class Calendar {
    static monthNames = {
        1: 'JAN',
        2: 'FEB',
        3: 'MAR',
        4: 'APR',
        5: 'MAY',
        6: 'JUN',
        7: 'JUL',
        8: 'AUG',
        9: 'SEP',
        10: 'OCT',
        11: 'NOV',
        12: 'DEC'
    };

    constructor(configSelector, targetSelector) {
        this._year = 2025;
        this._configDiv = document.querySelector(configSelector);
        this._targetDiv = document.querySelector(targetSelector);

        this._habits = [
            { name: "Vitamins" },
            { name: "Floss" },
            { name: "Floss" },
            { name: "Floss" },
            { name: "Floss" },
        ]
    }

    draw() {
        this.parseConfig();

        this.setPrintSize();

        let html = `<div class="calendar-head"><div class="calendar-year left-col">2025</div><div class="calendar-title">${this._title}</div></div>`;
        let daysProcessed = 0;
        html += this._months.reduce((calendarHtml, month) => {
            const monthHtml = this.generateMonthHtml(daysProcessed, month);
            daysProcessed += new Date(this._year, month, 0).getDate();
            return calendarHtml + monthHtml;
        }, '');

        this._targetDiv.innerHTML = html;
    }

    setPrintSize() {
        const paperContainer = document.querySelector('.printable, .paper-container');
        if (this._paperSize === 'letter') {
            paperContainer.style.width = '216mm';
            paperContainer.style.height = '279mm';
        } else if (this._paperSize === 'a4') {
            paperContainer.style.width = '210mm';
            paperContainer.style.height = '297mm';
        }
    }

    generateMonthHtml(daysProcessed, month) {
        const monthName = Calendar.monthNames[month];
        const daysInMonth = new Date(this._year, month, 0).getDate();

        const daysHtml = Array.from({ length: daysInMonth }, (_, i) => i + 1).reduce((html, day) => html + this.generateDayHtml(month, day), '');

        const habitsHtml = this._habits.reduce((html, this_habit) => {
            const habitDaysHtml = Array.from({ length: daysInMonth }, (_, i) => i + 1).reduce((habitHtml, day) => habitHtml + this.generateHabitHtml(daysProcessed, this_habit, month, day, '', ''), '');
            return html + `<div class="calendar-month-habit-row"><div class="calendar-habit-name left-col">${this_habit.name}&nbsp;</div><div class="calendar-month-habit-days">${habitDaysHtml}</div></div>`;
        }, '');

        return `<div class="calendar-month">
                    <div class="calendar-month-header">
                        <div class="calendar-month-name left-col">${monthName}</div>
                        <div class="calendar-month-days">
                            ${daysHtml}
                        </div>
                    </div>
                    ${habitsHtml}
                </div>`;
    }

    generateDayHtml(month, day) {
        const date = new Date(this._year, month - 1, day);
        const dayOfWeek = date.getDay();
        const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
        const dayClass = isWeekday ? 'weekday' : 'weekend';

        return `<div class="calendar-month-day cell ${dayClass}">${day}</div>`;
    }

    generateHabitHtml(daysProcessed, habit, month, day, weekdayIcon, weekendIcon) {
        const date = new Date(this._year, month - 1, day);
        const dayOfWeek = date.getDay();
        const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
        const icon = isWeekday ? weekdayIcon : weekendIcon;
        const dayClass = isWeekday ? 'weekday' : 'weekend';

        let content = "◯";
        if (habit.daysOfWeekEnabled && !habit.daysOfWeek.includes(dayOfWeek)) {
            content = "&nbsp; ";
        } 
        if (habit.nthDayEnabled && (daysProcessed + day - 1) % habit.nthDay !== 0) {
            content = "&nbsp;";
        }

        return `<span class="calendar-month-habit cell ${dayClass}">${content}</span>`;
    }

    parseConfig() {
        this.parseTitle();
        this.parsePaperSize();
        this.parseDuration();
        this.parseHabits();
    }

    parsePaperSize() {
        this._paperSize = this._configDiv.querySelector('.button-toggle.size.active').dataset.size;
    }

    parseTitle() {
        this._title = this._configDiv.querySelector('input[name="title"]').value;
    }

    parseDuration() {
        const activeButtons = this._configDiv.querySelectorAll('.button-toggle.active.month');
        this._months = Array.from(activeButtons).map(button => button.dataset.num);
    }

    parseHabits() {
        this._habits = Array.from(this._configDiv.querySelectorAll('#habits .habit')).filter(div => {
            return div.style.display !== 'none';
        }).map(div => {
            const name = div.querySelector('input[name="habit_name"]').value;
            const daysOfWeekEnabled = div.querySelectorAll('.optional-row[data-type="days-of-week"].active').length > 0;
            const daysOfWeek = daysOfWeekEnabled ? Array.from(div.querySelectorAll('[data-type="days-of-week"].optional-row .day.active')).map(span => parseInt(span.dataset.num)) : [0, 1, 2, 3, 4, 5, 6];
        

            const nthDayEnabled = div.querySelectorAll('.optional-row[data-type="every-nth-day"].active').length > 0;
            const nthDay = nthDayEnabled ? parseInt(div.querySelector('[data-type="every-nth-day"].optional-row input[type=text]').value) : 1;

            return { name, daysOfWeekEnabled, daysOfWeek, nthDayEnabled, nthDay };
        });
    }
}

export default Calendar;